import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import useSocket from 'hooks/useSocket';
import { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';
import { ordersTableColumns } from './columns';
import { OrderType } from './types';
import useNavigateUser from 'hooks/useNavigateUser';
import { useSignOut } from 'hooks/useSignOut';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortAsc,
  faSortDesc
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onItemClick: (value: {
    orderId: number;
    status: number;
    time: string;
  }) => void;
  onDataUpdate: (data: OrderType[]) => void;
};

const OrdersTable = ({ onItemClick, onDataUpdate }: Props) => {
  const { ordersList } = useLoaderData() as {
    totalPages: number;
    ordersList: OrderType[];
  };

  // console.log('loader res: ', ordersList);

  const { connect, disconnect, data, getOrdersInfo } = useSocket();
  // console.log('data from useSocket ordersTable::', data);
  const [sorting, setSorting] = useState([
    {
      id: 'operationDate',
      desc: true
    }
  ]);
  const navigateToAuth = useNavigateUser('/auth/sign-in');

  useEffect(() => {
    const ids = ordersList.map(i => i.bnplNumber);
    let intervalId: NodeJS.Timeout | null = null;
    // console.g('orders list: ', ordersList)
    // console.log('ids:', ids);

    if (ids.length > 0) {
      connect(() => {
        getOrdersInfo(ids);
        intervalId = setInterval(() => {
          getOrdersInfo(ids);
        }, 5_000);
      });
    } else {
      console.log('ids.length is 0');
    }

    return () => {
      intervalId && clearInterval(intervalId);
      disconnect();
    };
  }, [ordersList]);

  const res = useMemo(() => {
    const result = data.reverse().map((item, index) => ({
      ...ordersList[index],
      // orderNumber: item.bnpl_id,
      // orderNumber: ordersList[index].orderNumber,
      status: {
        time: item.rest_of_time,
        status: item.status_id
      }
    }));
    onDataUpdate(result);
    return result;
    // console.log('useMEMO');
  }, [data]);

  // console.log('res:', res);

  const { getFlatHeaders, getRowModel } = useReactTable({
    data: res as OrderType[],
    columns: ordersTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting: sorting
    }
  });

  return (
    <div className="scrollbar ms-n1 ps-1">
      <Table>
        <thead>
          <tr>
            {getFlatHeaders().map(header => {
              return (
                <th
                  key={header.id}
                  {...header.column.columnDef.meta?.headerProps}
                  className={classNames(
                    header.column.columnDef.meta?.headerProps?.className
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === 'asc'
                        ? 'Sort ascending'
                        : header.column.getNextSortingOrder() === 'desc'
                        ? 'Sort descending'
                        : 'Clear sort'
                      : undefined
                  }
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {header.column.getCanSort()
                    ? {
                        asc: (
                          <FontAwesomeIcon
                            className="mx-1 position-absolute fs-9"
                            icon={faSortDesc}
                          />
                        ),
                        desc: (
                          <FontAwesomeIcon
                            className="mx-1 position-absolute fs-9"
                            icon={faSortAsc}
                          />
                        )
                      }[header.column.getIsSorted() as string] ?? (
                        <FontAwesomeIcon
                          className="mx-1 position-absolute fs-9"
                          icon={faSort}
                        />
                      )
                    : null}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {getRowModel().rows.map(row => (
            <tr
              key={row.id}
              onClick={() => {
                if (!row) {
                  // console.log('sign out from orders-table');
                  useSignOut(navigateToAuth);
                }
                // console.log('ROW');
                // console.log('row oriinal:', row.original);

                const updatedStatus = res.find(
                  i => i.orderNumber === row.original.orderNumber
                )?.status.status;
                const updatedTime =
                  res?.find(i => i.orderNumber === row.original.orderNumber)
                    ?.status.time ?? '00:00';

                onItemClick({
                  // orderNumber: row.original.orderNumber,
                  // status: row.original.status.status,
                  // time:
                  //   res?.find(i => i.orderNumber === row.original.orderNumber)
                  //     ?.status.time ?? '00:00'
                  orderId: row.original.bnplNumber,
                  status: updatedStatus ? updatedStatus : 0,
                  time: updatedTime
                });
              }}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default OrdersTable;
